import React from 'react';
import { connect } from 'react-redux';

const MailTemplated = props => (
  <div>
    <h1>Mail - Templated</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus vitae aliquet nec ullamcorper sit. Id diam vel quam elementum pulvinar etiam. Ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Leo integer malesuada nunc vel risus commodo viverra maecenas. Sit amet massa vitae tortor condimentum lacinia quis vel. In eu mi bibendum neque egestas congue. Nulla facilisi nullam vehicula ipsum a arcu. Eget aliquet nibh praesent tristique magna sit amet. Nibh tellus molestie nunc non blandit massa enim nec dui. Ipsum dolor sit amet consectetur. Interdum velit laoreet id donec ultrices tincidunt arcu. Laoreet suspendisse interdum consectetur libero.</p>
  </div>
);

export default connect()(MailTemplated);
