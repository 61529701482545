import React from 'react';
import { connect } from 'react-redux';

const LogOut = props => (
  <div>
    <h1>Log Out</h1>
    <p>Goodbye.</p>
  </div>
);

export default connect()(LogOut);
