import React from 'react';
import { Route, Switch } from 'react-router';
import BaseLayout from './components/Layouts/BaseLayout';
import UnauthenticatedLayout from './components/Layouts/UnauthenticatedLayout';
import Home from './components/Home';
import Account from './components/Account/Account';
import Search from './components/Search/Search';
import MailPrintReady from './components/Mail/MailPrintReady';
import MailTemplated from './components/Mail/MailTemplated';
import MailMerge from './components/Mail/MailMerge';
import SMS from './components/SMS/SMS';
import Email from './components/Email/Email';
import Reports from './components/Reports/Reports';
import Admin from './components/Admin/Admin';
import LogOut from './components/Authentication/LogOut';
import LogIn from './components/Authentication/LogIn';
import Register from './components/Authentication/Register';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(far, fas);

// eslint-disable-next-line react/display-name
export default () => (
  <Switch>
    <AppRoute exact path='/' component={Home} layout={BaseLayout} />
    <AppRoute path='/account' component={Account} layout={BaseLayout} />
    <AppRoute path='/search' component={Search} layout={BaseLayout} />
    <AppRoute path='/mail-printready' component={MailPrintReady} layout={BaseLayout} />
    <AppRoute path='/mail-templated' component={MailTemplated} layout={BaseLayout} />
    <AppRoute path='/mail-merge' component={MailMerge} layout={BaseLayout} />
    <AppRoute path='/sms' component={SMS} layout={BaseLayout} />
    <AppRoute path='/email' component={Email} layout={BaseLayout} />
    <AppRoute path='/reports' component={Reports} layout={BaseLayout} />
    <AppRoute path='/admin' component={Admin} layout={BaseLayout} />
    <AppRoute path='/logout' component={LogOut} layout={UnauthenticatedLayout} />
    <AppRoute path='/login' component={LogIn} layout={UnauthenticatedLayout} />
    <AppRoute path='/register' component={Register} layout={UnauthenticatedLayout} />
  </Switch>
);

// eslint-disable-next-line react/no-multi-comp
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)