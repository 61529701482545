import React from 'react';
import { connect } from 'react-redux';

const Register = props => (
  <div>
    <h1>Register</h1>
    <p>Hello, for the first time.</p>
  </div>
);

export default connect()(Register);
