import React from 'react';
import { connect } from 'react-redux';

const SMS = props => (
  <div>
    <h1>SMS Upload</h1>
    <p>Consectetur a erat nam at lectus. Enim eu turpis egestas pretium aenean pharetra magna ac. Ornare aenean euismod elementum nisi quis eleifend. Dictum non consectetur a erat nam at lectus. Nunc sed blandit libero volutpat sed cras. Neque vitae tempus quam pellentesque. Pretium fusce id velit ut. Elementum sagittis vitae et leo duis ut diam. Eu nisl nunc mi ipsum faucibus vitae. Viverra justo nec ultrices dui sapien eget mi. Rhoncus urna neque viverra justo nec ultrices dui sapien. Mollis aliquam ut porttitor leo a diam sollicitudin tempor. Porttitor massa id neque aliquam vestibulum morbi blandit cursus.</p>
  </div>
);

export default connect()(SMS);
