import React from 'react';
import { connect } from 'react-redux';

const Email = props => (
  <div>
    <h1>Email Upload</h1>
    <p>Id diam vel quam elementum pulvinar etiam non quam lacus. Consequat semper viverra nam libero justo. Tellus id interdum velit laoreet id donec ultrices. Vel pretium lectus quam id leo in vitae turpis massa. Eu nisl nunc mi ipsum. Tellus integer feugiat scelerisque varius morbi enim. Lectus sit amet est placerat in egestas erat imperdiet. Amet facilisis magna etiam tempor orci eu lobortis. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Massa placerat duis ultricies lacus. Nulla pellentesque dignissim enim sit amet.</p>
  </div>
);

export default connect()(Email);
