import React from 'react';
import { connect } from 'react-redux';

const MailMerge = props => (
  <div>
    <h1>Mail - Merge</h1>
    <p>Enim neque volutpat ac tincidunt vitae semper quis lectus nulla. Ipsum dolor sit amet consectetur. Sagittis aliquam malesuada bibendum arcu. Volutpat odio facilisis mauris sit. Gravida in fermentum et sollicitudin ac orci phasellus egestas. Phasellus egestas tellus rutrum tellus pellentesque. Ac orci phasellus egestas tellus rutrum tellus pellentesque. Risus feugiat in ante metus dictum at tempor. Mauris augue neque gravida in fermentum et. Semper viverra nam libero justo laoreet. Imperdiet sed euismod nisi porta lorem mollis aliquam. Turpis cursus in hac habitasse.</p>
  </div>
);

export default connect()(MailMerge);
