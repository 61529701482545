import React from 'react';
import { connect } from 'react-redux';

const Admin = props => (
  <div>
    <h1>Admin Stuff</h1>
    <p>Donec massa sapien faucibus et molestie ac feugiat. Pretium viverra suspendisse potenti nullam ac tortor. Vitae elementum curabitur vitae nunc sed velit dignissim. Scelerisque purus semper eget duis. Ut morbi tincidunt augue interdum velit. Vel risus commodo viverra maecenas accumsan. Diam sit amet nisl suscipit adipiscing bibendum est. A pellentesque sit amet porttitor eget dolor morbi. Sit amet nisl purus in mollis nunc sed. Massa massa ultricies mi quis hendrerit dolor magna eget est. Massa vitae tortor condimentum lacinia quis vel eros donec ac. Aenean pharetra magna ac placerat vestibulum lectus mauris ultrices eros. Elementum integer enim neque volutpat ac tincidunt. Ultrices neque ornare aenean euismod elementum. Id diam maecenas ultricies mi eget mauris pharetra et ultrices.</p>
  </div>
);

export default connect()(Admin);
