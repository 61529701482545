import React from 'react';
import { connect } from 'react-redux';

const Account = props => (
  <div>
    <h1>My Account</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan tortor posuere ac ut. Cursus mattis molestie a iaculis at erat pellentesque adipiscing. Condimentum id venenatis a condimentum vitae. Nam libero justo laoreet sit amet cursus. Enim facilisis gravida neque convallis a cras. Nunc mi ipsum faucibus vitae aliquet. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit. Suspendisse interdum consectetur libero id faucibus. Dui faucibus in ornare quam viverra orci sagittis eu volutpat. Nunc sed velit dignissim sodales ut. Facilisis volutpat est velit egestas. Nec tincidunt praesent semper feugiat nibh. Volutpat diam ut venenatis tellus in metus vulputate eu scelerisque. Habitant morbi tristique senectus et netus et malesuada fames ac. Non pulvinar neque laoreet suspendisse interdum consectetur libero id. Ac ut consequat semper viverra nam libero justo. Sit amet nulla facilisi morbi tempus.</p>
  </div>
);

export default connect()(Account);
