import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './NavMenu.scss';

// eslint-disable-next-line react/display-name
export default props => (
  <Navbar inverse fixedTop fluid collapseOnSelect>
    <Navbar.Header>
      <Navbar.Brand>
        <Link to={'/'}>PostOnDemand</Link>
      </Navbar.Brand>
      <Navbar.Toggle />
    </Navbar.Header>
    <Navbar.Collapse>
      <Nav>
        <LinkContainer to={'/'} exact>
          <NavItem>
            <FontAwesomeIcon icon="home" /> Dashboard
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/account'} exact>
          <NavItem>
            <FontAwesomeIcon icon="user" /> My Account
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/search'}>
          <NavItem>
            <FontAwesomeIcon icon='search' /> Search
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/mail-printready'}>
          <NavItem>
            <FontAwesomeIcon icon='envelope' /> Print Ready Mail
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/mail-templated'}>
          <NavItem>
            <FontAwesomeIcon icon='ruler-combined' /> Templated Mail
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/mail-merge'}>
          <NavItem>
            <FontAwesomeIcon icon='copy' /> Mail Merge
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/sms'}>
          <NavItem>
            <FontAwesomeIcon icon='mobile-alt' /> SMS
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/email'}>
          <NavItem>
            <FontAwesomeIcon icon='at' /> HTML Email
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/reports'}>
          <NavItem>
            <FontAwesomeIcon icon='chart-bar' /> Reports
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/admin'}>
          <NavItem>
            <FontAwesomeIcon icon='user-shield' /> Admin
              </NavItem>
        </LinkContainer>
        <LinkContainer to={'/logout'}>
          <NavItem>
            <FontAwesomeIcon icon='sign-out-alt' /> Log Out
              </NavItem>
        </LinkContainer>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
