import React from 'react';
import { connect } from 'react-redux';

const Search = props => (
  <div>
    <h1>Search</h1>
    <p>Nulla aliquet enim tortor at auctor urna nunc id cursus. Eu scelerisque felis imperdiet proin fermentum leo vel orci. Feugiat pretium nibh ipsum consequat. Sem nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Nisl nunc mi ipsum faucibus vitae aliquet. Ut porttitor leo a diam sollicitudin. Condimentum lacinia quis vel eros donec. Eu scelerisque felis imperdiet proin fermentum leo. Praesent semper feugiat nibh sed pulvinar proin gravida. Rhoncus urna neque viverra justo nec ultrices dui sapien eget. Euismod nisi porta lorem mollis. Aenean et tortor at risus viverra adipiscing at. Congue nisi vitae suscipit tellus mauris a diam maecenas.</p>
  </div>
);

export default connect()(Search);
