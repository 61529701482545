import React from 'react';
import { connect } from 'react-redux';

const Reports = props => (
  <div>
    <h1>Reports</h1>
    <p>Quam quisque id diam vel. Nibh cras pulvinar mattis nunc sed blandit libero. Est placerat in egestas erat imperdiet sed euismod nisi. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Non curabitur gravida arcu ac tortor dignissim convallis. Enim praesent elementum facilisis leo vel fringilla est. Cursus turpis massa tincidunt dui ut ornare. Morbi quis commodo odio aenean sed adipiscing diam donec adipiscing. In arcu cursus euismod quis viverra nibh cras pulvinar mattis. Pellentesque adipiscing commodo elit at imperdiet dui. Odio morbi quis commodo odio aenean sed adipiscing.</p>
  </div>
);

export default connect()(Reports);
