import React from 'react';
import { connect } from 'react-redux';

const LogIn = props => (
  <div>
    <h1>Log In</h1>
    <p>Hello.</p>
  </div>
);

export default connect()(LogIn);
